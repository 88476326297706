import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import header_banner from "./../../image/theosis_bg.jpg";

export const Header = ({ coursesNumber }) => {
  return (
    <>
      <div className="container header">
        <div className="banner ">
          <img className="container" src={header_banner} alt="Top banner"></img>
          <div className="text">
            <p>Przemiana</p>
          </div>
        </div>
        <nav className="menu" id="myMenu">
          <NavLink to="/" exact>
            <button>Home</button>
          </NavLink>
          {"   |  "}
          <NavLink to="/courses">
            <button>Courses</button>
          </NavLink>
          {"   |  "}
          <NavLink to="/about">
            <button>About</button>
          </NavLink>
        </nav>
        <label className="number-courses">
          Available courses {coursesNumber}
        </label>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    coursesNumber: state.courses.length,
  };
}

Header.propTypes = {
  coursesNumber: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Header);
