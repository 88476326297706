const actionsType = {
  CREATE_COURSE: "CREATE_COURSE",
  LOAD_COURSES_SUCCESS: "LOAD_COURSES_SUCCESS",
  LOAD_AUTHORS_SUCCESS: "LOAD_AUTHORS_SUCCESS",
  CREATE_COURSES_SUCCESS: "CREATE_COURSES_SUCCESS",
  UPDATE_COURSE_SUCCESS: "UPDATE_COURSE_SUCCESS",
  BEGIN_API_CALL: "BEGIN_API_CALL",
  ERROR_API_CALL: "ERROR_API_CALL",
  DELETE_COURSE_OPTIMISTIC: "DELETE_COURSE_OPTIMISTIC",
  UPDATE_COURSE_SORT_SUCCESS: "UPDATE_COURSE_SORT_SUCCESS",
};

export default actionsType;
